import React, { useState, useEffect } from "react";
import { CTA, Brand, Navbar, Minerals } from "../components";
import { Link, useHistory } from "react-router-dom";
import Loader from "../components/Loader";
import { toast, Toaster } from "react-hot-toast";
import companyassets from "../assets/companyassets.png"
import companiesContext from '../context/companiesContext';




const ProfileScreen = () => {
   // Get the signIn function from the UserContext
   const { getCompanies,companies,loading,setRole } = companiesContext();

  const Card = ({ role, title, description, buttonTitle, link }) => {

    const history = useHistory();

    const cardLink = () => {
      setRole(role)
      history.push(link);
    }

    return (
      <div className="ServiceCardd" onClick={cardLink}>
        {/* <div className={`ServiceCardd_img ${cardClass}`}>
          <div className="plus"><p>+</p></div>
        </div> */}
        <h3><img src={companyassets} alt="company image"  style={{height: "200px", width: "200px",marginRight:"5px", display: "inline-block"}}/>{title.toUpperCase()}</h3>
        <div className="line"></div>
        <p>{description}</p>
        <p style={{ color: "#557C56" }}>{buttonTitle}</p>
      </div>
    )
  };

  const Viewport = ({companz}) => (
    <div className="viewport" style={{ marginBottom: "60px" }}>
     <div className="ServiceCardds">
        {companz.map((company, index) => (
          <Card 
           key={company.companyID}
           cardClass="ServiceCardd10"
           title={company.company}
           description={company.companyEmail}
           buttonTitle={company.role}
           link={`/company/${company.companyID}`}
           role={company.role}
         />
        ))}
       
        <Card
          cardClass="ServiceCardd8"
          title="Companies"
          description="."
          buttonTitle="Learn More"
          link="/company/page"
        />
        <Card
          cardClass="ServiceCardd11"
          title="Companies"
          description="."
          buttonTitle="Learn More"
          link="/company/page"
        />
      </div> 
    </div>
  );

  useEffect(() => {
     getCompanies()
     console.log("COMPANIES "+JSON.stringify(companies))
  }, []);

  return (

    <>
      <div className="gradieant__bg">
        <Navbar />
      </div>
      <Toaster toastOptions={{ duration: 4000 }} />
      {companies? <Viewport companz={companies}/> :
       <h1 style={{color: "orange", textAlign: "center", fontSize: "50px"}}>No Companies Found</h1> } 
    </>
  );
};

export default ProfileScreen;