import React, { useRef, useEffect, useState } from "react";
// import { socket } from "../services/socketInitialize"
import { io } from "socket.io-client";
import MenuContext from "../context/MenuContext";
import companiesContext from "../context/companiesContext"
import UserContext from '../context/userContext.js';
const Queue = () => {
  const [queue, setQueue] = useState([]);
  const { queueItem, department } = MenuContext();
  const { selectedCompanyID, role } = companiesContext();
  const { user } = UserContext();
  const { getTokenFromStorage, logout } = UserContext.getState();


  const orderContainerRef = useRef(null);


  // Scroll up by a certain amount (e.g., 100 pixels)
  //For Events on the right side of the dashboard
  const scrollUp = () => {
    if (orderContainerRef.current) {
      orderContainerRef.current.scrollBy({
        top: -100, // Negative value scrolls up
        behavior: "smooth",
      });
    }
  };

  // Scroll down by a certain amount (e.g., 100 pixels)
  //For Events on the right side of the dashboard
  const scrollDown = () => {
    if (orderContainerRef.current) {
      orderContainerRef.current.scrollBy({
        top: 100, // Positive value scrolls down
        behavior: "smooth",
      });
    }
  };
  const token = getTokenFromStorage()
  useEffect(() => {

    const socket = io('http://localhost:3000');

    socket.on("connect", () => {
      console.log("Connected to the server");
    });

    socket.on("connect_error", (err) => {
      console.error("Connection error:", err.message);
      return
    });
    // Request to watch a specific queue
    socket.emit('watchQueue', { queueName: queueItem, companyId: selectedCompanyID, role, expectedRole: department, userId: user.sub });

    socket.on('watchQueueResponse', (data) => {
      console.log("RESPONSE DATA :" + JSON.stringify(data))
      if (data.success) {
        setQueue(data.queue.queueItems); // Initialize the queue state
      }
    })
    // Handle the initial queue data
    socket.on('queueData', (data) => {
      console.log("QUEUE DATA :" + JSON.stringify(data))
      if (data.success) {
        setQueue(data.queue.queueItems); // Initialize the queue state
      }
    });

    // Handle real-time queue updates
    socket.on('queueUpdated', (data) => {
      if (data.success) {
        setQueue(data.queue.queueItems); // Update the queue state with live data
      }
    });

    return () => {
      socket.disconnect();
    };

  }, []);

  return (
    <>
      <div className='order-element'>
        <div className='element-header' style={{ background: "linear-gradient(to top, #dfe9f3 0%, white 100%)", color: "black" }}>ORDERS</div>
        <div className='order-scroll up' id='order-scrollup' onClick={scrollUp}><i className="fa fa-chevron-up"></i></div>
        <div className='order-container' id='order-container' ref={orderContainerRef}>
          {/* {events.map((event, index) => (
                                        <div className='order-item' id='order-item' key={event._id} onClick={() => EventAssets(event._id)}>
                                            <h1 style={{ textAlign: "Left", color: "black" }}>{event.eventName}</h1>
                                            <p style={{ color: "black" }}>Status: <span className='main-color'>{event.status}</span><br />{event.receipts} : Amount per asset ${event.assetValue}</p>
                                            <p style={{ color: "black" }}>Creation Date: {event.createdAt}<br /> Description: {event.description}</p>
                                        </div>
                                    ))} */}
          {Array.isArray(queue) && queue.length === 0 && (
            <>
              <div className='order-item' id='order-item' style={{backgroundImage: "linear-gradient(0deg, #08AEEA 0%, #2AF598 100%)" ,paddingTop: "20px",paddingBottom: "20px"}} >
                <p style={{ textAlign: "Left", color: "white", fontSize: "30px" ,paddingTop: "20px",paddingBottom: "20px" }}>😊Queue is Empty</p>
                <p style={{ color: "white", fontSize: "17px"  }}>No Queries<span className='main-color'>Present</span><br /></p>
              </div>
            </>
          )}
          {/* <div className='order-item'>
            <h1>Order #313-55416218</h1>
            <p>Status: <span className='main-color'>Dispatched</span><br />Tracking Number: 1431 2349 11</p>
            <p>Order Date: 12/24/16<br /> Customer Contact: random.guy@gmail.com</p>
          </div>
          <div className='order-item highlight'>
            <h1>Order #535-71122781</h1>
            <p>Status: <span className='highlight'>Awaiting Shipment</span><br />Tracking Number: -</p>
            <p>Order Date: 12/24/16<br /> Customer Contact: aname.store@aol.com</p>
          </div>
          <div className='order-item highlight'>
            <h1>Order #455-51133742</h1>
            <p>Status: <span className='highlight'>Awaiting Shipment</span><br />Tracking Number: -</p>
            <p>Order Date: 12/24/16<br /> Customer Contact: special.order@yahoo.com</p>
          </div>
          <div className='order-item hold'>
            <h1>Order #734-45422551</h1>
            <p>Status: <span className='inactive'>On Hold</span><br />Tracking Number: -</p>
            <p>Order Date: 12/20/16<br /> Customer Contact: nobody123@gmail.com</p>
          </div>
          <div className='order-item hold'>
            <h1>Order #532-13422331</h1>
            <p>Status: <span className='inactive'>On Hold</span><br />Tracking Number: -</p>
            <p>Order Date: 12/19/16<br /> Customer Contact: random333.star@gmail.com</p>
          </div>
          <div className='order-item hold'>
            <h1>Order #922-25491351</h1>
            <p>Status: <span className='inactive'>On Hold</span><br />Tracking Number: -</p>
            <p>Order Date: 12/19/16<br /> Customer Contact: santa@gmail.com</p>
          </div> */}
        </div>
        <div className='order-scroll' id='order-scrolldown' onClick={scrollDown}><i className="fa fa-chevron-down"></i></div>
      </div>
    </>
  )
}

export default Queue