import { create } from 'zustand';
import { jwtDecode } from "jwt-decode";
import {getCompaniesService, getAssetsService,getClientAssetsService, getEventService, addEventService} from '../services/companies'

// Zustand store for auth
const companiesContext = create((set, get) => ({
  companies: null,
  selectedCompanyID: null,
  clientCompanyID: null,
  clientCompanyName: null,
  events: null,
  assets: null,
  clientAssets: null,
  role: null,
  loading: false,
  
  getCompanies: async () => {
    set({ loading: true });
    set({ role: null});
    const response = await getCompaniesService();
    if (response !== undefined && Array.isArray(response.companies)) {
      console.log("Companies : " + JSON.stringify(response.companies));
      set({ companies: response.companies, loading: false });
    } else {
      set({ companies: null, loading: false });
    }
  },

  getEvents: async (assetCategoryID, companyID) => {
    set({ loading: true });
    const response = await getEventService(assetCategoryID, companyID);
    console.log("Response data: " + JSON.stringify(response));
    if (response !== undefined && Array.isArray(response)) {
      console.log("Events : " + JSON.stringify(response));
      set({ events: response, loading: false });
    } else {
      set({ events: null, loading: false });
    }
  },

  getAssets: async (companyID) => {
    set({ loading: true });
    const response = await getAssetsService(companyID);
    if (response !== undefined && Array.isArray(response.assets)) {
      set({ assets: response.assets, loading: false });
      set({ selectedCompanyID: companyID });
    } else {
      set({ assets: null, loading: false });
    }
  },

  createEvent: async (companyID, eventName, description, assetCategoryID, assetValue, receipts, eventLoop, installment) => {
    set({ loading: true });
    const response = await addEventService(companyID, eventName, description, assetCategoryID, assetValue, receipts, eventLoop, installment);
    if (response !== undefined) {
      set({ loading: false });
      // Adjusted conditional checks and used `get().getEvents` to call the function
      if (response.message !== 'Failed to create event' && 
          response.message !== 'Asset category not found' && 
          response.message !== 'User does not have permission to create an event for this company' && 
          response.message !== 'No roles found for the user') {
        // get().getEvents(assetCategoryID, companyID);  // Corrected function call
      }
    } else {
      set({ assets: null, loading: false });
    }
  },

  setRole: async (role) => {
    set({ loading: true });
    set({ role: role, loading: false });
  },
  getClientAssets: async (companyID, clientID) => {
    set({ loading: true });
    const response = await getClientAssetsService(companyID, clientID);
    if (response !== undefined && Array.isArray(response)) {
      console.log("MY ASSETS "+ JSON.stringify(response))
      set({ clientAssets: response, loading: false });
    } else {
      set({ clientAssets: null, loading: false });
    }
  },
  setClientCompanyID: async(id) =>{
    set({ clientCompanyID: id });
  },
  setClientCompanyName: async(name) =>{
    set({ clientCompanyName: name });
  }
}));

export default companiesContext;