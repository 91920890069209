import React, { useRef, useMemo, useEffect, useState } from "react";
// import { GoogleMap, LoadScript, MarkerF, Circle, Polygon, InfoWindow } from '@react-google-maps/api';
import { Link, useHistory, useLocation } from "react-router-dom";
import companiesContext from '../context/companiesContext';
import backarrow from "../assets/left.png"
import logo from "../assets/logo1.png";
import userlocation from "../assets/currentlocation.png"
// import locator from "../assets/userlocation2.png"
// import approved from "../assets/approvelocation.png"

import house from "../assets/housing.webp"
import { CTA, Brand, Navbar, Slider } from "../components";
import MenuOptions from "../components/DashboardMenus"
import Receptionist from "../components/DashboardReception"
import ATLAS from "../components/DashboardATLAS"
import WorkBench from "../components/DashboardWorkBench"
import DashboardAdmin from "../components/DashboardAdmin"
import Queue from "../components/DashboardQueue"
import GeospatialMapping from "../components/DashboardCarto"
import MapPlot from "../components/MapPlot"
import {
    Footer,
    Blog,
    Possibility,
    Features,
    WhatGPT3,
    Header,
    Services,
} from "../containers";

import UserContext from '../context/userContext';
import MenuContext from "../context/MenuContext";
import "./css/dashboard.css"


const DashboardPScreen = () => {

    // const [map, setMap] = useState();
    // const [position, setPosition] = useState({ lat: null, lng: null });
    const [currentPosition, setCurrentPosition] = useState({ lat: null, lng: null });
    const [isApiLoaded, setIsApiLoaded] = useState(false); // State to track when the API is loaded
    const [showInfoWindow, setShowInfoWindow] = useState(false);
    const [hoveredCoordinate, setHoveredCoordinate] = useState(null);
    const [hoverData, setHoverData] = useState(null);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [uncompliantAssets, setUncompliantAssets] = useState(null);
    const [compliantAsset, setCompliantAsset] = useState(null);
    const [circleAssett, setCircleAsset] = useState(null);
    const location = useLocation();
    const pathId = location.pathname.split("/")[2]
    const [categoryId, setCategoryId] = useState(null);
    const [documentlink, setDocument] = useState(null);
    // State to store the ID of the selected row
    // const [selectedAssetID, setSelectedAssetID] = useState(null);
    const [action, setAction] = useState(null);
    //Display Main Dashboard
    const [dashboard, setDashboard] = useState(true);
    const [settings, setSettings] = useState(null);
    // const [assetCategory, setAssetCategory] = useState(null);
    const [optionSelected, setOptionSelected] = useState(false)
    //Show Add Event Form
    const [addMilestone, setMilestone] = useState(null);
    const [showEvents, setShowEvent] = useState(null);

    //show map state so that only show map when it's needed
    const [showMap, setShowMap] = useState(true)
    const [workbench, setWorkbench] = useState(false);
    //Setting States
    const [assignRole, setAssignRecords] = useState(false)

    //CONTEXT
    const { logout, user } = UserContext();
    const { getAssets, getEvents, assets, role, loading, events, selectedCompanyID, createEvent } = companiesContext();
    const {queueItem, menuItem, department, setMenuItem } = MenuContext();




    // COMPONENTS

    const Card = ({ title, description, buttonTitle, link }) => {

        const history = useHistory();

        const cardLink = () => {
            history.push(link);
        }

        return (
            <div className="ServiceCardd" style={{ padding: "20px" }}>
                {/* <div className={`ServiceCardd_img ${cardClass}`}>
              <div className="plus"><p>+</p></div>
            </div> */}
                <h3>{title}</h3>
                <div className="line"></div>
                <p style={{ marginTop: "15px" }}>{description}</p>
                {/* <button onClick={cardLink} className="cardsbuttonz" style={{ width: "45%", height: "10%", marginRight: "auto", marginLeft: "auto", marginBottom: "60px", background: "linear-gradient( 110.3deg,  rgba(73,93,109,1) 4.3%, rgba(49,55,82,1) 96.7% )" }}>{buttonTitle}</button> */}
            </div>
        )
    };
    // SideBar
    const SideBar = ({ user }) => {
        const Service = (action) => {
            if (action == "action") {
                setMenuItem(null)
                setDashboard(false)
                setSettings(false)
                setWorkbench(false)
                setMilestone(false)
                setAction(true)
                setShowMap(true)

            } else if (action == "dashboard") {
                setMenuItem(null)
                setAction(false)
                setSettings(false)
                setWorkbench(false)
                setMilestone(false)
                setDashboard(true)
                setShowMap(true)

            } else if (action == "workbench") {
                setMenuItem(null)
                setWorkbench(true)
                setShowMap(false)
                setSettings(false)
            } else if (action == "settings") {
                setMenuItem(null)
                setWorkbench(false)
                setMilestone(false)
                setDashboard(false)
                setShowMap(false)
                setSettings(true)
            }
        }
        return (
            <>
                <div className='dashsidebar'>
                {user && role ? (
                    <div style={{display: "flex", paddingBottom: "8%"}}>
                         <img src="https://icons.veryicon.com/png/o/miscellaneous/black-yellow-linear-simple-icon/user-230.png" style={{width: "100px", height: "100px"}}/>
                         <div className="user-infor" style={{ paddingTop: "7px" }}>
                         <div style={{ color: "#FFBB0F", paddingBottom: "8px"}}>{user.name}</div>
                         <div>{user.email}</div>
                         <div>{role}</div>
                         </div>
                    </div>
                ) : null}
                    {user ? (
                        <>
                            <div onClick={() => Service("dashboard")} className='navigation-elm'><span className='nav-text'><i className="fa fa-fw fa-area-chart"></i><span className='text-short'></span> <span className='text-long'>ATLAS</span></span>
                            </div>
                            {/* <div class='sub-navigation-elm'>Asset Performance</div>
                        <div class='sub-navigation-elm'>Asset Actions</div>
                        <div class='sub-navigation-elm'>Users</div> */}
                        </>
                    ) : null}
                    {user ? (
                        <>
                            <div onClick={() => Service('workbench')} className='navigation-elm'><span className='nav-text'><i className="fa fa-solid fa-folder-open"></i><span className='text-short' style={{ marginLeft: "12px" }}></span> <span className='text-long'>WORKBENCH</span></span> </div>
                        </>
                    ) : null}

                    {user ? (
                        <>
                            <div onClick={() => Service("action")} className='navigation-elm'><span className='nav-text'><i className="fa  fa-solid fa-coins"></i><span className='text-short' style={{ marginLeft: "12px" }}>FINANCIAL</span> <span className='text-long'>MILESTONES</span></span> </div>
                        </>
                    ) : null}

                    <div className='navigation-elm'><Link to="/profile"><span className='nav-text'><i className="fa fa-solid fa-user"></i><span className='text-short' style={{ marginLeft: "12px" }}>COMPANIES</span> <span className='text-long'>REPOSITORY</span></span></Link>
                    </div>

                    {user ? (
                        <>
                            <div className='navigation-elm'><span className='nav-text'><i className="fa fa-solid fa-chart-simple"></i><span className='text-short' style={{ marginLeft: "12px" }}>EVENT </span> <span className='text-long'>LOGS</span>
                                <div className='update-number'>3</div>
                            </span>
                            </div>
                        </>) : null}

                    {user ? (
                        <>
                            <div className='navigation-elm'><span className='nav-text'><i className="fa fa-solid fa-chart-simple"></i><span className='text-short' style={{ marginLeft: "12px" }}>QUEUE</span> <span className='text-long'>TREES</span>
                                <div className='update-number'>3</div>
                            </span>
                            </div>
                        </>) : null}

                    {user ? (
                        <>
                            <div onClick={() => Service("settings")} className='navigation-elm'><span className='nav-text'><i className="fa fa-fw fa-gear"></i><span className='text-short'>ADMIN</span></span>
                            </div>
                        </>
                    ) : null}

                </div>
            </>
        )
    }

    // Metrics
    const Metrics = ({ revenue, unitsSold }) => (
        <>
            <div className='dashrow'>
                <div className='key-metrics white-border'>
                    <div className='small'>Revenue</div>
                    {revenue}$
                </div>
                <div className='key-metrics blue-border'>
                    <div className='small'>Units Sold</div>{unitsSold}
                </div>
            </div>
        </>
    );

    //Statistics
    const Statics = () => {
        return (
            <div className='dashrow'>
                <div className='piechart-element'>
                    <div className='piechart-label'>
                        <div className='label-heading'>Asset</div>
                        <p className='small'>Compliance <span className='main-color'>Completion</span>.Rate. </p>
                    </div>
                    <div className='piechart-percent main-color'>68%</div>
                    <div className='piechart-container'>

                        <canvas id="piechart"></canvas>
                    </div>
                </div>
                <div className='piechart-element'>

                    <div className='piechart-label'>
                        <div className='label-heading'>Revenue</div>
                        <p className='small'>Realized  <span className='main-color'> 360 </span> Assets</p>
                    </div>
                    <div className='piechart-percent main-color'>$100</div>
                    <div className='piechart-container'>
                        <canvas id="piechart2"></canvas>
                    </div>

                </div>
                <div className='piechart-element'>
                    <div className='piechart-label'>
                        <div className='label-heading'>Revenue</div>
                        <p className='small'>Expected
                            <span className='main-color'>2000</span> Assets .
                        </p>
                    </div>
                    <div className='piechart-percent main-color'>$10000</div>
                    <div className='piechart-container'>
                        <canvas id="piechart3"></canvas>
                    </div>

                </div>
            </div>
        )
    }
    //Charts
    const Charts = () => {
        return (
            <div className='linechart-element'><canvas id="linechart"> </canvas>
            </div>
        )
    }
    //END COMPONENTS
  

    // //Information Window on selected markers on the map
    // const handleInfoWindowClose = () => {
    //     setShowInfoWindow(false);
    // };

    // //When you click a marker
    // const handleMarkerHover = (id) => {
    //     for (const asset of selectedAsset.assets) {
    //         if (asset._id == id) {
    //             setHoveredCoordinate(asset);
    //             setPosition({ lat: asset.centerCoordinates.lat, lng: asset.centerCoordinates.lng })
    //             if (
    //                 asset &&
    //                 asset.assetDocuments &&
    //                 asset.assetDocuments[0] &&
    //                 asset.assetDocuments[0].documentDownloadLink
    //             ) {
    //                 setDocument(`https://drive.google.com/file/d/${getDriveFileId(asset.assetDocuments[0].documentDownloadLink)}/view`);
    //             }
    //         }
    //     }
    //     setShowInfoWindow(true);
    // };

    // //Category Selection belonging to a selected company
    // const categorySelect = (id) => {
    //     for (const asset of assets) {
    //         if (asset._id == id) {
    //             setCompliantAsset(null)
    //             setAssetCategory(asset.category)
    //             setSelectedAsset(asset);
    //             setUncompliantAssets(asset);
    //         }
    //     }

    //     //Get Events here
    //     if (selectedCompanyID !== null && id !== null) {
    //         getEvents(id, selectedCompanyID);
    //     }

    // }

    // //For searching for assets by name or phone number
    // const searchAsset = () => {
    //     const searchAsset = searchRef.current.value;
    //     console.log('Search String :' + searchAsset);

    //     // If the search input is empty, return without filtering
    //     if (searchAsset === "") {
    //         return;
    //     }

    //     // Filter assets by operatorPhone or operatorName
    //     const filteredAssets = selectedAsset.assets.filter(asset =>
    //         asset.operatorPhone.includes(searchAsset) ||
    //         asset.operatorName.toLowerCase().includes(searchAsset.toLowerCase())
    //     );

    //     // Clear the search input
    //     searchRef.current.value = "";

    //     console.log("Searched Assets: " + JSON.stringify(filteredAssets));

    //     // Update the state with the filtered assets
    //     setSelectedAsset({ assets: filteredAssets });
    // };

    // //Move directly to the circled coordinates
    // const circleAsset = (id) => {
    //     for (const asset of selectedAsset.assets) {
    //         if (asset._id == id) {
    //             setCircleAsset(asset);
    //             setPosition({ lat: asset.centerCoordinates.lat, lng: asset.centerCoordinates.lng })
    //         }
    //     }
    // }

    // //For getting a url to use later for viewing on the browser
    // function getDriveFileId(url) {
    //     const urlParams = new URLSearchParams(url.split('?')[1]); // Extract query string
    //     const fileId = urlParams.get('id'); // Get the 'id' parameter
    //     return fileId;
    // }
    // // Function to handle row click
    // const handleRowClick = (assetID) => {
    //     setSelectedAssetID(assetID); // Update the selected asset ID
    //     circleAsset(assetID); // Call the circleAsset function with the asset ID
    // };

    // Function Get Compliant assets
    const EventAssets = (eventId) => {
        const filteredAssets = events.filter(event => event._id === eventId);
        if (filteredAssets.length > 0) {
            // Third array to store matching objects from array1
            const matchingAssets = [];

            // Filter selectedAsset.assets and simultaneously store matching objects
            const remainingAssets = uncompliantAssets.assets.filter(asset => {
                const matchedFilteredAsset = filteredAssets[0].performedOn.find(filteredAsset => asset._id === filteredAsset.assetID);

                if (matchedFilteredAsset) {
                    // Create a copy of the asset object and add fields from matchedFilteredAsset
                    const updatedAsset = {
                        ...asset,
                        valueCollected: matchedFilteredAsset.valueCollected,
                        doneOn: matchedFilteredAsset.implementationDate
                    };  // Add the new fields to the asset

                    matchingAssets.push(updatedAsset);  // Push updated asset to matchingAssets
                }

                return !matchedFilteredAsset;  // Exclude matching assets from remainingAssets
            });

            // Update the state for compliantAsset and uncompliantAssets
            setCompliantAsset(matchingAssets);
            setMilestone(false)
            setShowEvent(true)
            setUncompliantAssets(prev => ({ ...prev, assets: remainingAssets }));
        }
    };

    const AddMilestone = () => {
        setShowEvent(false)
        setShowMap(false)
        setMilestone(true)
    }

    const showEvent = () => {
        setDashboard(false)
        setMilestone(false)
        setAction(true)
        setShowMap(true)
    }

    const milestoneOptions = [
        {
            title: "Add Milestone",
            action: AddMilestone
        },
        {
            title: "Stop Milestone",
            action: AddMilestone
        },
        {
            title: "Show Milestone",
            action: showEvent
        }
    ]


    const repoOptions = [
        {
            title: "Event Compliance",
            action: AddMilestone
        },
        {
            title: "Stop Event",
            action: AddMilestone
        },
        {
            title: "Show Events",
            action: showEvent
        }
    ]

    const settingOptions = [
        {
            title: "Assign Role",
            action: AddMilestone
        },
        {
            title: "Employee Logs",
            action: AddMilestone
        },
        {
            title: "Users",
            action: AddMilestone
        },
    ]
    const settingsRepo = [
        {
            id: 1,
            title: "Assign Role",
            description: "Assign Tasks to Employees",
            buttonTitle: "Roles",
            action: ""
        },
        {
            id: 2,
            title: "Employee Logs",
            description: "Employee Logs",
            buttonTitle: "Logs",
            action: ""
        },
        {
            id: 3,
            title: "Users",
            description: "All Users",
            buttonTitle: "Users",
            action: ""
        },
    ]

    useEffect(() => {
        console.log("Company : "+ JSON.stringify(assets))
        getAssets(pathId);
        if(menuItem !== null && department !== null) {
            setWorkbench(false)
            setDashboard(false)
        }
    }, [menuItem]);



    return (
        <>
            <div className='dashcontainer'>
                <SideBar user={user} />
                {showMap && (
                    <>
                        <Metrics revenue={30} unitsSold={20} />
                        <Charts />
                        {/* <Statics /> */}
                    </>)}

                {user && role == "Director" || role == "Manager" || role == "SuperAdmin" ? (
                    <div className='dashrow'>
                        <div className='stats-element'>
                           {dashboard && !workbench && (
                            <ATLAS assets={assets} loading={loading}/>
                           )}
                          
                           {workbench && (
                            <WorkBench />
                           )}

                            {settings && (
                                <DashboardAdmin />
                            )}
                          
                            {menuItem ==="LogCoordinates" && department==="CARTOGRAPHY" ? (
                                <>
                                  <GeospatialMapping assets={assets} loading={loading}/>
                                </>
                            ): null}
                        </div>
                       {/* ROW 2 */}
                        {queueItem && (
                            <Queue />
                        )}
                       {menuItem ==="LogCoordinates" && department==="CARTOGRAPHY" ? (
                                <>
                                  <MapPlot assets={assets} />
                                </>
                         ): null}
                    </div>  
                ) : null}

                {/* <div class='element-header social'>Workflow</div>

                <div class='dashrow todo'>
                    <div class='social-element'>
                        <div class='chatbox-container'>
                            <div class='anna-image'></div>
                            <div class='chatbox-left'><span class='username'>Ann:</span>Hi <span class='highlight'>@Jimmy</span>, please make sure to have a look at <a href='#' class='main-color'>#413-8148622</a>, needs dispatch ASAP.</div>
                        </div>
                        <div class='chatbox-container'>
                            <div class='anna-image'></div>
                            <div class='chatbox-left'><span class='username'>Ann:</span>Aw sorry, forgot to attach the updated invoice.</div>
                        </div>
                        <div class='chatbox-container'>
                            <div class='jimmy-image'></div>
                            <div class='chatbox-left'><span class='username'>Jimmy:</span>Heya <span class='highlight'>@Ann</span>, don't you worry - I'm here to save the day. Order is already dispached!</div>
                        </div>
                        <p class='spacing-adjuster'></p>
                        <div class='chat-input'>
                            <textarea rows='1' placeholder='Add a comment to this workflow...'></textarea>
                            <div class='submit-icon'><i class="fa fa-paper-plane-o"></i></div>
                        </div>
                    </div>
                    <div class='todo-element'>
                        <div class='table-header todo'>
                            <div class='country-select'>Files</div>
                            <div class='country-select'>Todos</div>
                        </div>
                        <div class='file-container small'>

                            <ul className="dashul">
                                <li className="dashli"><span><i class="fa fa-file-pdf-o"></i> invoice#413-814updated.pdf</span></li>
                                <li className="dashli"><i class="fa fa-file-excel-o"></i> revenue-summary.xls</li>
                                <li className="dashli"><i class="fa fa-file-pdf-o"></i> region30_map.pdf</li>
                            </ul>

                            <div class='draghere'><i class="fa fa-lg fa-cloud-upload"></i>Drag new files here</div>

                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
};

export default DashboardPScreen;



{/* <div class='stats-container' id='stats-element'>
{loading && (<Loader />)}
<div class='map-container'>
    {position.lat && position.lng ? (
        <div>

            <Receptionist
                addEvent={addMilestone}
                records={records}
                setAddEvent={setMilestone}
                setShowEvent={setShowEvent}
                setShowMap={setShowMap}
            />


            <LoadScript
                googleMapsApiKey='AIzaSyCMSkqh_rKJthabvItAZKNenF1ksHZbGfE'
                mapIds={['ba39c0a5e6fb900e']}
                onLoad={() => setIsApiLoaded(true)} // Set API loaded state to true when ready
            >

                {showMap && !settings && (
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={{ lat: position.lat, lng: position.lng }}
                        options={{
                            mapId: 'ba39c0a5e6fb900e',
                            mapTypeId: 'satellite' // Set map type to satellite
                        }}
                        zoom={18}
                        onLoad={(map) => setMap(map)}
                    >

                        {/* Current Location Marker */}
//                         {currentPosition.lat && currentPosition.lng && bounceAnimation && (  // Ensure marker displays if position is set
//                             <>
//                                 <MarkerF
//                                     position={{ lat: currentPosition.lat, lng: currentPosition.lng }}
//                                     icon={{
//                                         url: userlocation,
//                                         scaledSize: {
//                                             width: 50,
//                                             height: 50,
//                                         },
//                                     }}
//                                     // label={{text:`You`,color:'#FFBE98', fontSize: "13px", fontWeight: "bold"}}
//                                     animation={bounceAnimation}
//                                 />
//                             </>
//                         )}


//                         {dashboard && selectedAsset && dropAnimation && selectedAsset.assets.map((asset) => (
//                             <MarkerF
//                                 key={asset._id}
//                                 position={{ lat: asset.centerCoordinates.lat, lng: asset.centerCoordinates.lng }}
//                                 icon={{
//                                     url: locator,
//                                     scaledSize: {
//                                         width: 50,
//                                         height: 50,
//                                     },
//                                 }}
//                                 animation={bounceAnimation}
//                                 onClick={() => handleMarkerHover(asset._id)}
//                             />
//                         ))}

//                         {action && uncompliantAssets && dropAnimation && uncompliantAssets.assets.map((asset) => (
//                             <MarkerF
//                                 key={asset._id}
//                                 position={{ lat: asset.centerCoordinates.lat, lng: asset.centerCoordinates.lng }}
//                                 icon={{
//                                     url: locator,
//                                     scaledSize: {
//                                         width: 50,
//                                         height: 50,
//                                     },
//                                 }}
//                                 animation={bounceAnimation}
//                                 onClick={() => handleMarkerHover(asset._id)}
//                             />
//                         ))}

//                         {action && compliantAsset && dropAnimation && compliantAsset.map((asset) => (
//                             <MarkerF
//                                 key={asset._id}
//                                 position={{ lat: asset.centerCoordinates.lat, lng: asset.centerCoordinates.lng }}
//                                 icon={{
//                                     url: approved,
//                                     scaledSize: {
//                                         width: 50,
//                                         height: 50,
//                                     },
//                                 }}
//                                 animation={bounceAnimation}
//                                 onClick={() => handleMarkerHover(asset._id)}
//                             />
//                         ))}

//                         {circleAssett && (
//                             <Circle
//                                 center={{ lat: circleAssett.centerCoordinates.lat, lng: circleAssett.centerCoordinates.lng }} // Another location
//                                 options={{ ...circleOptions }}
//                             />
//                         )}

//                         {showInfoWindow && hoveredCoordinate && (
//                             <InfoWindow
//                                 position={hoveredCoordinate.centerCoordinates}
//                                 onCloseClick={handleInfoWindowClose}
//                             >
//                                 <div style={{ background: "linear-gradient( 109.6deg,  rgba(0,0,0,0.93) 11.2%, rgba(63,61,61,1) 78.9% )", height: "350px", width: "350px" }}>
//                                     <h1 style={{ fontSize: '20px', color: "#FFF1DB", textAlign: "left", padding: "10px" }}>{hoveredCoordinate.operatorName}</h1>
//                                     <p style={{ fontSize: "15px", color: "white", padding: "10px" }}>ID: {hoveredCoordinate.operatorID}</p>
//                                     <p style={{ fontSize: "15px", color: "white", padding: "10px" }}>Phone: {hoveredCoordinate.operatorPhone}</p>
//                                     <p style={{ fontSize: "15px", color: "white", padding: "10px" }}>Certificate Number: {hoveredCoordinate.assetNumber}</p>
//                                     {documentlink && <Link onClick={() => window.open(documentlink, "_blank")} style={{ background: "#FFA27F", padding: "15px", color: "white", marginTop: "20px", marginLeft: "10px", textDecoration: "none" }}>
//                                         View File
//                                     </Link>}
//                                 </div>
//                             </InfoWindow>
//                         )}
//                     </GoogleMap>
//                 )}


//             </LoadScript>
//         </div>
//     ) : (
//         <Loader />
//     )}


//     {/* SETTINGS FOR ADMIN AND DIRECTOR */}
//     {settings && (
//         <>
//             <MenuOptions menuTitle="ADMIN OPTIONS" options={settingOptions} />
//             {/* {assignRole && (
//                 <>
                
//                 </>
//              )} */}
//         </>

//     )}

//     {/* {settings &&
//         <div className="options">
//             {settingsRepo.map((setting) => (
//                 <Card
//                     key={setting.id}
//                     cardClass="ServiceCardd10"
//                     title={setting.title}
//                     description={setting.description}
//                     buttonTitle={setting.buttonTitle}
//                     link={setting.action}
//                 />
//             ))}
//         </div>
//     } */}
// </div>

// {/* END SETTINGS FOR ADMIN */}



// {assets && !addMilestone && !settings ? (
//     <>
//         <div class='dashtable-header' style={{ marginTop: "10px", marginBottom: "10px" }}>
//             <div class='element-header' style={{ background: "linear-gradient(to top, #dfe9f3 0%, white 100%)", color: "black" }}>ASSET CATEGORIES</div>
//             {assets.map((asset, index) => (
//                 <div className='country-select' key={asset._id} onClick={() => categorySelect(asset._id)}>{asset.category}</div>
//             ))}
//         </div>

//     </>
// ) :
//     null
// }




// {!compliantAsset && !addMilestone && !settings && (
//     <>
//         <div style={{ marginTop: "35px", display: "flex" }}>
//             <input type="text" name="assetSearch" placeholder="Search Asset" required="required" className="dashSearchInput" ref={searchRef} /> <button style={{ backgroundColor: "#FF8225", width: "80px", height: "44px", marginLeft: "5px" }} onClick={searchAsset}>Search</button>
//         </div>
//         <ul className="tableHead" style={{ color: "black" }} >
//             <li>Operator Name</li>
//             <li>Operator ID</li>
//             <li>Operator Phone</li>
//             <li>Asset Name</li>
//             <li>Asset Number</li>
//             <li>Latitude</li>
//             <li>Longitude</li>
//         </ul>
//         <div class='stats-table' style={{ zIndex: "100" }}>
//             <table class='performance-table' style={{ zIndex: "100" }}>
//                 <tbody className="table-body">
//                     {selectedAsset ? (
//                         <>
//                             {selectedAsset.assets.map((asset, index) => (
//                                 <tr key={asset._id}
//                                     className={`assetRow ${selectedAssetID === asset._id ? 'selected' : ''}`}
//                                     onClick={() => handleRowClick(asset._id)} >
//                                     <td class='table-desc'>{asset.operatorName}</td>
//                                     <td>{asset.operatorID}</td>
//                                     <td>{asset.operatorPhone}</td>
//                                     <td>{asset.assetName}</td>
//                                     <td>{asset.assetNumber}</td>
//                                     <td>{asset.centerCoordinates.lat}</td>
//                                     <td>{asset.centerCoordinates.lng}</td>
//                                 </tr>
//                             ))}
//                         </>
//                     ) : null}

//                 </tbody>
//             </table>
//         </div>
//     </>
// )}

// {action && showEvents && compliantAsset && !settings && (
//     <>
//         <ul className="tableHead" style={{ background: "linear-gradient( 109.6deg,  rgba(0,0,0,0.93) 11.2%, rgba(63,61,61,1) 78.9% )" }}>
//             <li>Operator Name</li>
//             <li>Operator ID</li>
//             <li>Operator Phone</li>
//             <li>Asset Name</li>
//             <li>Asset Number</li>
//             <li>Amount</li>
//             <li>Date</li>
//         </ul>
//         <div class='stats-table' style={{ zIndex: "100" }}>
//             <table class='performance-table' style={{ zIndex: "100" }}>
//                 <tbody className="table-body">
//                     {compliantAsset && compliantAsset.map((asset, index) => (
//                         <tr key={asset._id}
//                             className={`assetRow ${selectedAssetID === asset._id ? 'selected' : ''}`}
//                             style={{ backgroundColor: "#72BF78" }}
//                             onClick={() => handleRowClick(asset._id)} >
//                             <td class='table-desc'>{asset.operatorName}</td>
//                             <td>{asset.operatorID}</td>
//                             <td>{asset.operatorPhone}</td>
//                             <td>{asset.assetName}</td>
//                             <td>{asset.assetNumber}</td>
//                             <td>{asset.valueCollected}</td>
//                             <td>{asset.doneOn}</td>
//                         </tr>
//                     ))}

//                 </tbody>
//             </table>
//         </div>

//     </>
// )}
// </div> */}