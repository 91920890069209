//**************************************************************
//     Cartography Service is used by a Cartographer           *
//     the user.                                               *
//     Updated on 21 January 2025        by Delight Jambani    *
//**************************************************************

// Import necessary modules (if axios is imported elsewhere, you can omit this)
import axios from 'axios';
import UserContext from '../context/userContext.js';

// For redirecting after signing up 
const { getTokenFromStorage, logout } = UserContext.getState();

const savePolygonService = async (companyID, categoryId, assetNumber,points) => {
    // Configuration for the axios request, setting headers for JSON data
    const token = getTokenFromStorage()
    if (!token) {
        return
    }
    const config = {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `Bearer ${token}`,  // Attach token to Authorization heade
        },
    };

    try {
        // Make the API call to save polygon, and config
        const { data } = await axios.post(`/api/protected/companies/cartography`,{companyID, categoryId, assetNumber,points},config);
        return data;
    } catch (error) {
        // Handle the error gracefully and log it to the console for debugging
        console.error("Error during login:", error.response.status);
        console.log("Error during login:", error);
        // if (error.response.status === 401 || error.response.status === 403) {
        //     // Token is invalid or expired
        //     logout();
        //     // Optionally redirect to login
        //     return;
        // }
    }
};

export {savePolygonService} 