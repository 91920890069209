import React, { useRef, useEffect, useState } from "react";
const MenuOptions = ({menuTitle, options }) => {
  const [optionSelected, setOptionSelected] = useState(false)
  return (
    <>
      <div className='dashtable-header' style={{ marginTop: "10px", marginBottom: "10px" }}>
        <div className='element-header' style={{ background: "linear-gradient(to top, #dfe9f3 0%, white 100%)", color: "black" }}>{menuTitle}</div>
        {options.map((option, index) => (
          <div key={index} className={optionSelected ? `country-selected` : `country-select`} onClick={option.action}> {option.title}</div>
        ))}
      </div>
    </>
  );
}

export default MenuOptions;