import React, { useRef, useMemo, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { GoogleMap, LoadScript, MarkerF, Circle, Polygon, InfoWindow } from '@react-google-maps/api';
import cartographyContext from "../context/cartographyContext"
import locator from "../assets/userlocation2.png"
import approved from "../assets/approvelocation.png"
import Loader from "./Loader";

const polygonOptions = {
  fillColor: "#FF5733",
  fillOpacity: 0.4,
  strokeColor: "#FF5733",
  strokeOpacity: 1,
  strokeWeight: 2,
};
const DashGoogleMap = ({ containerStyle, circleOptions, selectedAsset, nonecompliantAssets, compliantAsset, circleAssett, position, setPosition }) => {
  const [isApiLoaded, setIsApiLoaded] = useState(false); // State to track when the API is loaded
  const [showInfoWindow, setShowInfoWindow] = useState(false); //when an item is clicked we would see the information
  const [documentlink, setDocument] = useState(null);
  const [hoveredCoordinate, setHoveredCoordinate] = useState(null);

  const { points } = cartographyContext();
  const mapRef = useRef(null);
  const mapOptions = {
    center: { lat: position.lat, lng: position.lng },
    zoom: 18,
    mapId: 'ba39c0a5e6fb900e',
    mapTypeId: 'satellite', // Set map type to satellite
  };

  // Animation for the marker; only defined if google.maps is loaded
  const bounceAnimation = useMemo(() => {
    return isApiLoaded && window.google && window.google.maps ? window.google.maps.Animation.BOUNCE : null;
  }, [isApiLoaded]);

  const dropAnimation = useMemo(() => {
    return isApiLoaded && window.google && window.google.maps ? window.google.maps.Animation.DROP : null;
  }, [isApiLoaded]);

  // Functions on the Map
  //When you click a marker
  const handleMarkerHover = (id) => {
    for (const asset of selectedAsset.assets) {
      if (asset._id == id) {
        setHoveredCoordinate(asset);
        setPosition({ lat: asset.centerCoordinates.lat, lng: asset.centerCoordinates.lng })
        if (
          asset &&
          asset.assetDocuments &&
          asset.assetDocuments[0] &&
          asset.assetDocuments[0].documentDownloadLink
        ) {
          setDocument(`https://drive.google.com/file/d/${getDriveFileId(asset.assetDocuments[0].documentDownloadLink)}/view`);
        }
      }
    }
    setShowInfoWindow(true);
  };


  //For getting a url to use later for viewing on the browser
  function getDriveFileId(url) {
    const urlParams = new URLSearchParams(url.split('?')[1]); // Extract query string
    const fileId = urlParams.get('id'); // Get the 'id' parameter
    return fileId;
  }
  //Information Window on selected markers on the map
  const handleInfoWindowClose = () => {
    setShowInfoWindow(false);
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });

        // setCurrentPosition({
        //   lat: position.coords.latitude,
        //   lng: position.coords.longitude,
        // })
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
    console.log("POINTS :" + JSON.stringify(points))
  }, []);

  useEffect(() => {
    if (points.length > 0 && mapRef.current) {
      const latestPoint = points[points.length - 1]; // Get the latest point
      mapRef.current.panTo({ lat: latestPoint.lat, lng: latestPoint.long }); // Pan to the latest point
    }
  }, [points]); // Trigger whenever points change

  return (
    <>
      {position.lat && position.lng ? (
        <LoadScript
          googleMapsApiKey='AIzaSyCMSkqh_rKJthabvItAZKNenF1ksHZbGfE'
          mapIds={['ba39c0a5e6fb900e']}
          onLoad={() => setIsApiLoaded(true)} // Set API loaded state to true when ready
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            ref={mapRef}
            options={mapOptions}
            onLoad={(map) => (mapRef.current = map)}
          >

            {selectedAsset && dropAnimation && selectedAsset.assets.map((asset) => (
              <MarkerF
                key={asset._id}
                position={{ lat: asset.centerCoordinates.lat, lng: asset.centerCoordinates.lng }}
                icon={{
                  url: locator,
                  scaledSize: {
                    width: 50,
                    height: 50,
                  },
                }}
                animation={bounceAnimation}
                onClick={() => handleMarkerHover(asset._id)}
              />
            ))}

            {nonecompliantAssets && dropAnimation && nonecompliantAssets.assets.map((asset) => (
              <MarkerF
                key={asset._id}
                position={{ lat: asset.centerCoordinates.lat, lng: asset.centerCoordinates.lng }}
                icon={{
                  url: locator,
                  scaledSize: {
                    width: 50,
                    height: 50,
                  },
                }}
                animation={bounceAnimation}
                onClick={() => handleMarkerHover(asset._id)}
              />
            ))}

            {compliantAsset && dropAnimation && compliantAsset.map((asset) => (
              <MarkerF
                key={asset._id}
                position={{ lat: asset.centerCoordinates.lat, lng: asset.centerCoordinates.lng }}
                icon={{
                  url: approved,
                  scaledSize: {
                    width: 50,
                    height: 50,
                  },
                }}
                animation={bounceAnimation}
                onClick={() => handleMarkerHover(asset._id)}
              />
            ))}

            {circleAssett && (
              <Circle
                center={{ lat: circleAssett.centerCoordinates.lat, lng: circleAssett.centerCoordinates.lng }} // Another location
                options={{ ...circleOptions }}
              />
            )}

            {points && (
              <>
                {points.map((point) => (
                  <Circle
                    center={{ lat: point.lat, lng: point.long }}  // Another location
                    options={{ ...circleOptions }}
                  />
                ))}
              </>
            )}

            {/* Draw a polygon if there are enough points */}
            {points.length > 2 && (
              <Polygon
                paths={points.map((point) => ({ lat: point.lat, lng: point.long }))}
                options={polygonOptions}
              />
            )}

            {selectedAsset && dropAnimation && selectedAsset.assets.map((asset) => (
              <Polygon
                paths={asset.polygon.map((point) => ({ lat: point.coords.latitude, lng: point.coords.longitude }))}
                options={polygonOptions}
              />
            ))}
            {showInfoWindow && hoveredCoordinate && (
              <InfoWindow
                position={hoveredCoordinate.centerCoordinates}
                onCloseClick={handleInfoWindowClose}
              >
                <div style={{ background: "linear-gradient( 109.6deg,  rgba(0,0,0,0.93) 11.2%, rgba(63,61,61,1) 78.9% )", height: "350px", width: "350px" }}>
                  <h1 style={{ fontSize: '20px', color: "#FFF1DB", textAlign: "left", padding: "10px" }}>{hoveredCoordinate.operatorName}</h1>
                  <p style={{ fontSize: "15px", color: "white", padding: "10px" }}>ID: {hoveredCoordinate.operatorID}</p>
                  <p style={{ fontSize: "15px", color: "white", padding: "10px" }}>Phone: {hoveredCoordinate.operatorPhone}</p>
                  <p style={{ fontSize: "15px", color: "white", padding: "10px" }}>Certificate Number: {hoveredCoordinate.assetNumber}</p>
                  {documentlink && <Link onClick={() => window.open(documentlink, "_blank")} style={{ background: "#FFA27F", padding: "15px", color: "white", marginTop: "20px", marginLeft: "10px", textDecoration: "none" }}>
                    View File
                  </Link>}
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        </LoadScript>
      ) : (
        <Loader />
      )}
    </>
  );
}


export default DashGoogleMap