import React, { useRef, useMemo, useEffect, useState } from "react";
import MenuOptions from "./DashboardMenus"
import DashGoogleMap from "./DashGoogleMap";
import Loader from "./Loader";

const atlasOptions = [
  {
    title: "Jurisdactions",
    action: ""
  },
  {
    title: "Dimensions",
    action: ""
  },
  {
    title: "Approved",
    action: ""
  }
]

//Map Style Properties
const containerStyle = {
  width: '100%',
  height: '500px',
  marginRight: "auto",
  marginLeft: "auto",
  borderBottom: "6px double #F8FAFC",
  borderTop: "6px double #F8FAFC",
  borderRight: "6px double #F8FAFC",
  borderLeft: "6px double #F8FAFC",
  // cursor: plotting ? 'crosshair' : 'auto', // Set cursor style based on plottingMode
};


// Circle Style Properties
const circleOptions = {
  strokeColor: "#F4F6FF",
  strokeOpacity: 0.9,
  strokeWeight: 2,
  fillColor: "linear-gradient( 97.3deg,  rgba(25,50,70,0.81) 10.7%, rgba(250, 81, 9, 0.72) 39.5%, rgba(255,192,0,0.81) 69.7% )",
  fillOpacity: 0.4,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 3, // Radius in meters
  zIndex: 1,
};

//END Map Style Properties
const ATLAS = ({assets, loading}) => {
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [uncompliantAssets, setUncompliantAssets] = useState(null);
  const [compliantAsset, setCompliantAsset] = useState(null);
  const [circleAssett, setCircleAsset] = useState(null);
  const [selectedAssetID, setSelectedAssetID] = useState(null);
  const [position, setPosition] = useState({ lat: null, lng: null });

  const [assetCategory, setAssetCategory] = useState(null);


  // ATLAS FUNCTIONS
  //Category Selection belonging to a selected company
  const categorySelect = (id) => {
    for (const asset of assets) {
      if (asset._id == id) {
        setCompliantAsset(null)
        setAssetCategory(asset.category)
        setSelectedAsset(asset);
        setUncompliantAssets(asset);
      }
    }

    //Get Events here
    // if (selectedCompanyID !== null && id !== null) {
    //   getEvents(id, selectedCompanyID);
    // }

  }
   
  //For searching for assets by name or phone number
  const searchRef = useRef()
  const searchAsset = () => {
    const searchAsset = searchRef.current.value;
    console.log('Search String :' + searchAsset);

    // If the search input is empty, return without filtering
    if (searchAsset === "") {
      return;
    }

    // Filter assets by operatorPhone or operatorName
    const filteredAssets = selectedAsset.assets.filter(asset =>
      asset.operatorPhone.includes(searchAsset) ||
      asset.operatorName.toLowerCase().includes(searchAsset.toLowerCase())
    );

    // Clear the search input
    searchRef.current.value = "";

    console.log("Searched Assets: " + JSON.stringify(filteredAssets));

    // Update the state with the filtered assets
    setSelectedAsset({ assets: filteredAssets });
  };

  //Move directly to the circled coordinates
  const circleAsset = (id) => {
    for (const asset of selectedAsset.assets) {
      if (asset._id == id) {
        setCircleAsset(asset);
        setPosition({ lat: asset.centerCoordinates.lat, lng: asset.centerCoordinates.lng })
      }
    }
  }

  // Function to handle row click
  const handleRowClick = (assetID) => {
    setSelectedAssetID(assetID); // Update the selected asset ID
    circleAsset(assetID); // Call the circleAsset function with the asset ID
  };



  return (
    <>
      {!loading && (<MenuOptions menuTitle="ATLAS OPTIONS" options={atlasOptions} />)}
      <div className='stats-container' id='stats-element'>
      <div className='map-container'>
       {loading && (<Loader />)}
        <DashGoogleMap
          containerStyle={containerStyle}
          circleOptions={circleOptions}
          position={position}
          setPosition={setPosition}
          selectedAsset={selectedAsset}
          nonecompliantAssets={uncompliantAssets}
          compliantAsset={compliantAsset}
          circleAssett={circleAssett}
        />


        {assets ? (
          <>
            <div className='dashtable-header' style={{ marginTop: "10px", marginBottom: "10px" }}>
              <div className='element-header' style={{ background: "linear-gradient(to top, #dfe9f3 0%, white 100%)", color: "black" }}>CATEGORIES</div>
              {assets.map((asset, index) => (
                <div className='country-select' key={asset._id} onClick={() => categorySelect(asset._id)}>{asset.category}</div>
              ))}
            </div>

          </>
        ) :
          null
        }

        {/* TABLE OF ASSETS */}
        {!compliantAsset && (
          <>
            <div style={{ marginTop: "35px", display: "flex" }}>
              <input type="text" name="assetSearch" placeholder="Search Asset" required="required" className="dashSearchInput" ref={searchRef} /> <button style={{ backgroundColor: "#FF8225", width: "80px", height: "44px", marginLeft: "5px" }} onClick={searchAsset}>Search</button>
            </div>
            <ul className="tableHead" style={{ color: "black" }} >
              <li>Operator Name</li>
              <li>Operator ID</li>
              <li>Operator Phone</li>
              <li>Asset Name</li>
              <li>Asset Number</li>
              <li>Latitude</li>
              <li>Longitude</li>
            </ul>
            <div className='stats-table' style={{ zIndex: "100" }}>
              <table className='performance-table' style={{ zIndex: "100" }}>
                <tbody className="table-body">
                  {selectedAsset ? (
                    <>
                      {selectedAsset.assets.map((asset, index) => (
                        <tr key={asset._id}
                          className={`assetRow ${selectedAssetID === asset._id ? 'selected' : ''}`}
                          onClick={() => handleRowClick(asset._id)} >
                          <td className='table-desc'>{asset.operatorName}</td>
                          <td>{asset.operatorID}</td>
                          <td>{asset.operatorPhone}</td>
                          <td>{asset.assetName}</td>
                          <td>{asset.assetNumber}</td>
                          <td>{asset.centerCoordinates.lat}</td>
                          <td>{asset.centerCoordinates.lng}</td>
                        </tr>
                      ))}
                    </>
                  ) : null}

                </tbody>
              </table>
            </div>
          </>
        )}
    </div>
      </div>
    </>
  );
}

export default ATLAS