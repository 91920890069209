import { create } from 'zustand';
import {assignRoleService, searchUserService} from '../services/adminstration'

// Zustand store for auth
const adminContext = create((set) => ({
  message: null,
  foundUser: null,
  loading: false,

  assignRole: async (userPhone,companyID,proposedRole) => {
    set({ loading: true });
    // assign role API
    const response = await assignRoleService(userPhone,companyID,proposedRole);
    console.log("Response role :"+ JSON.stringify(response))
    if (response) {
      set({ message: response.message });
      set({loading: false});
    }
  },

  searchUser: async (phone) => {
    set({loading: true})
    //search find User
    const response = await searchUserService(phone);
    if (response.message == "User found") {
        const userFound = response.user
        // console.log("DATA :"+JSON.stringify(userFound));
        set({foundUser: userFound, loading: false})
    }else{
      console.log("RESPONSE FROM SEARCH "+response)
      set({loading: false})
    }
  },
  removeSearch: async () => {
    set({foundUser: null, loading: false})
  }
}));

export default adminContext;