import { create } from 'zustand';

// Zustand store for auth
const MenuContext = create((set) => ({
  queueItem: null,
  menuItem: null,
  department: null,
  error: null,
  loading: false,

  setQueueItem: async (item) => {  //Setting Queue Name
    set({queueItem: null,loading: true });
    set({queueItem: item, loading: false})
  },
  setMenuItem: async (item) => {  //Setting Queue Name
    set({menuItem: null,loading: true });
    set({menuItem: item, loading: false})
  },
  setDepartment: async (depart) => {
    set({ department: null,loading: true });
    set({department: depart, loading: false})
  },
}));

export default MenuContext;