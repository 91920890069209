// Implement signin with Firebase
// From Auth0 to firebase is the current change that seems feasible
// Updated by Delight Jambani 15 July 2024: 09:26


import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast"; // Toast 
import UserContext from '../context/userContext';
import Loader from "../components/Loader";
import cubeLoading from "../assets/cubeLoading.gif";

import "./css/signup.css"


const SignIn = () => {

    // Get the signIn function from the UserContext
    const { login, user,loading } = UserContext();

    // Local state for email, name, and password
    const emailRef = useRef()
    const passwordRef = useRef()


    // For redirecting after signing up 
    const navigate = useHistory();

    // Handle the form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await login(emailRef.current.value, passwordRef.current.value);
            navigate.push("/profile");
        } catch {
            // toast.error("Something went wrong" + error)
        }

    };

    return (
        <>
            <div className="login">
             {loading && (<Loader />)}
                {/* {!loading && <img src={cubeLoading} alt="Rotating" />} */}
                <h1>Log In</h1>
                <form onSubmit={handleSubmit}>
                    <Toaster toastOptions={{ duration: 4000 }} />
                    <input type="email" name="u" placeholder="Email" required="required" ref={emailRef} className="formInput" />
                    <input type="password" name="p" placeholder="Password" required="required" ref={passwordRef} className="formInput" />
                    <button type="submit" className="btn btn-primary btn-block btn-large" disabled={loading}>Let me in</button>
                </form>
                <div style={{ margin: "10px", fontSize: "15px", textAlign: "center" }}>
                    Need an account? <Link to="/signup">Sign up</Link> <br /> <br />
                    <Link to="/forgot-password" style={{color: "orangered"}}>Forgot Password?</Link>
                </div>
            </div>
        </>
    )
};

export default SignIn;