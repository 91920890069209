import React, { useRef, useEffect, useState } from "react";

import MenuContext from "../context/MenuContext";
import companiesContext from "../context/companiesContext"
const WorkBench = () => {
  const { setQueueItem, setMenuItem,setDepartment, department } = MenuContext();
  const { role } = companiesContext();
  const [selectedDepartment, setSelectedDepartment] = useState(null); // State to track selected department
  const [forbidden, setForbidden] = useState(false); // State to track selected department

  //Functions and Features of WORKBENCH
  const workbenchTiles = {
    FRONTDESK: [
      { id: 1, title: "Queries", description: "All Customer Requests", buttonTitle: "Requests", action: "Customer Queries" , menuOption: null},
      { id: 2, title: "Payments", description: "Payment Tracking", buttonTitle: "Payments", action: null , menuOption: "Payments" },
      { id: 3, title: "Tasks", description: "Assigned Tasks", buttonTitle: "Tasks", action: null ,menuOption: "Tasks"},
      { id: 4, title: "Reporting", description: "Document Reporting", buttonTitle: "Reporting", action: null, menuOption: "Reports" },
      { id: 5, title: "Performance Stats", description: "Performance Stats", buttonTitle: "Performance Stats", action: null, menuOption: "PerformanceStats" }
    ],
    SALES: [
      { id: 1, title: "On Sale", description: "Assets on Sale", buttonTitle: "On Market", action: null, menuOption: "OnSale" },
      { id: 2, title: "Sold", description: "Sold Assets", buttonTitle: "Sold", action: null, menuOption: "Sold" },
      { id: 3, title: "Tasks", description: "Assigned Tasks", buttonTitle: "Tasks", action: null, menuOption: "Tasks" },
      { id: 4, title: "Requests", description: "All Customer Requests", buttonTitle: "Requests", action: "Customer Requests", menuOption: null },
      { id: 5, title: "Reporting", description: "Document Reporting", buttonTitle: "Reporting", action: null,menuOption: "Reports" },
      { id: 6, title: "Performance Stats", description: "Performance Stats", buttonTitle: "Performance Stats", action: null, menuOption: "PerformanceStats" }
    ],
    CARTOGRAPHY: [
      { id: 1, title: "Log Coordinates", description: "Satellite Mapping", buttonTitle: "Log Coordinates", action: null, menuOption: "LogCoordinates" },
      { id: 2, title: "Tasks", description: "Dimensional Mapping Tasks", buttonTitle: "Tasks", action: null, menuOption: "Tasks" },
      { id: 3, title: "Reporting", description: "Document Reporting", buttonTitle: "Reporting", action: null ,menuOption: "Reports"},
      { id: 4, title: "Cartography Requests", description: "All Customer Requests", buttonTitle: "Cartography Requests", action: "Cartography Requests", menuOption: null },
      { id: 5, title: "Performance Stats", description: "Performance Stats", buttonTitle: "Performance Stats", action: null, menuOption: "PerformanceStats"}
    ],
    SURVEY: [
      { id: 1, title: "Log Survey", description: "Map Survey Coordinates", buttonTitle: "Log Survey", action: null, menuOption: "LogSurvey" },
      { id: 2, title: "Surveyed Zones", description: "Geospatial Survey", buttonTitle: "Surveyed Zones", action: null, menuOption: "Surveyed Zones" },
      { id: 3, title: "Tasks", description: "Survey Jobs", buttonTitle: "Survey Jobs", action: null, menuOption: "Tasks" },
      { id: 4, title: "Survey Requests", description: "All Customer Survey Requests", buttonTitle: "SurveyRequests", action: "Survey Requests", menuOption: null },
      { id: 5, title: "Reporting", description: "Document Reporting", buttonTitle: "Reporting", action: null, menuOption: "Reports" },
      { id: 6, title: "Performance Stats", description: "Performance Stats", buttonTitle: "Performance Stats", action: null, menuOption: "PerformanceStats" }
    ],
    MANAGEMENT: [
      { id: 1, title: "Assets", description: "Available Assets", buttonTitle: "Assets", action: null, menuOption: "Assets" },
      { id: 2, title: "Task Delegation", description: "Assign tasks based on employee skills and project needs", buttonTitle: "Delegation", action: null, menuOption: "AssignTasks" },
      { id: 3, title: "Employee Requests", description: "All Employee Requests", buttonTitle: "Employee Requests", action: "Employee Requests", menuOption: null },
      { id: 4, title: "Statistical Analysis", description: "Analysis of Perfomance", buttonTitle: "Statistics", action: null, menuOption: "PerformanceStats" },
      { id: 5, title: "Reporting", description: "Document Reporting", buttonTitle: "Reporting", action: null, menuOption: "Reports" },
    ],
    // LAWYER: [
    //   { id: 1,title: "Pending Sale Approvals",description: "Sales Proposed by Manager",buttonTitle: "Sale Approvals",action: ""},
    //   { id: 2,title: "Financial Reports",description: "Reports",buttonTitle: "Reports",action: ""},
    //   { id: 3,title: "Statistical Analysis",description: "Analysis of Perfomance",buttonTitle: "Statistics",action: ""},
    //   { id: 4,title: "Reporting",description: "Document Reporting",buttonTitle: "Reporting",action: ""}
    // ]
  };
  // Card Component for Tiles
  const Card = ({ title, description, queueItem, menuOption }) => {

      const featureEvent = ()=>{
        setQueueItem(queueItem)
        setMenuItem(menuOption)
        console.log("Menu Item "+ menuOption )
        console.log("Queue Item "+ queueItem )
        console.log("DEPARTMENT "+ department)
      }
    return (
      <div className="ServiceCardd" style={{ padding: "20px" }} onClick={featureEvent}>
        <h3>{title}</h3>
        <div className="line"></div>
        <p style={{ marginTop: "15px" }}>{description}</p>
      </div>
    )
  };

  const departmentEvent = (department) => {
    //Confirm if user is permited to Open a Department 
    setForbidden(false)
    if(role.toUpperCase() == department || role.toUpperCase() == "SUPERADMIN"  ){
      setDepartment(department)
      setSelectedDepartment(department)
    }else{
      setForbidden(true)
    }
  }

  return (
    <div className='stats-container' id='stats-element'>
      <div className='map-container'>
        <div className='dashtable-header' style={{ marginTop: "10px", marginBottom: "10px" }}>
          <div
            className='element-header'
            style={{ background: "linear-gradient(to top, #dfe9f3 0%, white 100%)", color: "black" }}
          >
            DEPARTMENTS
          </div>

          {/* Display Departments */}

          {Object.keys(workbenchTiles).map((department) => (
            <div
              key={department}
              className={`${selectedDepartment === department ? 'country-selected' : 'country-select'}`}
              onClick={() => departmentEvent(department)}
            >
              {department}
            </div>
          ))}
        </div>

        {/* Display Tiles for Selected Department */}
        {selectedDepartment && (
          <div className="options">
            {workbenchTiles[selectedDepartment].map((tile) => (
              <Card
                key={tile.id}
                title={tile.title}
                description={tile.description}
                queueItem={tile.action}
                menuOption={tile.menuOption}
              />
            ))}
          </div>
        )}

        {forbidden && !selectedDepartment && (
           <div className="ServiceCardd" style={{ padding: "20px", width: "60%" ,backgroundImage: "radial-gradient( circle 916px at 8.2% 23.5%,  rgba(171,45,121,1) 0%, rgba(176,53,127,1) 17%, rgba(221,131,2,0.86) 47.2%, rgba(247,246,246,1) 100.2%, rgba(247,246,246,1) 100.2% )"}}>
           <h3 style={{color: "white", fontSize: "40px"}}>🚫 Access Denied ‼️</h3>
           <div className="line"></div>
           <p style={{ marginTop: "15px", color: "white" }}>You need clearance</p>
         </div>
        )}
      </div>
    </div>
  );
};

export default WorkBench;