//**************************************************************
//     Admin Service is to do adminstrative work               *
//                                                             *
//     Updated on 05 January 2025 12:02 by Delight Jambani     *
//**************************************************************

// Import necessary modules (if axios is imported elsewhere, you can omit this)
import axios from 'axios';
import UserContext from '../context/userContext.js';

// For redirecting after signing up 
const { getTokenFromStorage, logout } = UserContext.getState();
// loginService handles user login by making a POST request with email and password


const assignRoleService = async (userPhone,companyID,proposedRole ) => {
    // Configuration for the axios request, setting headers for JSON data
    const token = getTokenFromStorage()
    if (!token) {
        logout()
        return
    }

    const config = {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `Bearer ${token}`,  // Attach token to Authorization header
        },
    };

    try {
        // Make the API call to get events
        const { data } = await axios.post(`/api/protected/admin/assign/role`, { userPhone, companyID, proposedRole }, config);
        return data;
    } catch (error) {
        console.log("ERROR ASSIGNING ROLE :" + error)
        // Handle the error gracefully and log it to the console for debugging
        if (error.response.status === 401 || error.response.status === 403) {
            // Token is invalid or expired
            // logout();
            console.log("ERROR :" + error.message)
            // Optionally redirect to login
            return;
        }
        if (error.response.status === 404 || error.response.status === 500) {
            console.log("Error retrieving assigning role")
        }
    }
};

const searchUserService = async (phone) => {
    // Configuration for the axios request, setting headers for JSON data
    const token = getTokenFromStorage()
    if (!token) {
        logout()
        return
    }

    const config = {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `Bearer ${token}`,  // Attach token to Authorization header
        },
    };

    try {
        // Make the API call to get events
        
        const { data } = await axios.post(`/api/protected/admin/search/user`, {phone}, config);
        console.log("search data :"+ data)
        return data;
    } catch (error) {
        // Handle the error gracefully and log it to the console for debugging
        if (error.response.status === 401 || error.response.status === 403) {
            // Token is invalid or expired
            logout();
            console.log("ERROR :" + error.message)
            // Optionally redirect to login
            return;
        }
        if (error.response.status === 404 || error.response.status === 500) {
            console.log("Error retrieving assigning role")
        }
    }
};

export { assignRoleService, searchUserService}