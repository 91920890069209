import React, { useState, useMemo, useRef, useEffect } from "react";
import { CTA, Brand, Navbar, Slider } from "../components";
import locator from "../assets/userlocation2.png"
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";
import { GoogleMap, LoadScript, MarkerF, Polygon, InfoWindow } from '@react-google-maps/api';
import companiesContext from '../context/companiesContext';

const ClientAssetsScreen = () => {
    const [isApiLoaded, setIsApiLoaded] = useState(false); // State to track when the API is loaded
    const { id1, id2 } = useParams(); // Extract the two IDs from the URL
    const [position, setPosition] = useState({ lat: 0, lng: 0 })
    const mapRef = useRef(null);



    const mapOptions = {
        center: { lat: position.lat, lng: position.lng },
        zoom: 18,
        mapId: 'ba39c0a5e6fb900e',
        mapTypeId: 'satellite', // Set map type to satellite
    };
    // Animation for the marker; only defined if google.maps is loaded
    const bounceAnimation = useMemo(() => {
        return isApiLoaded && window.google && window.google.maps ? window.google.maps.Animation.BOUNCE : null;
    }, [isApiLoaded]);

    const dropAnimation = useMemo(() => {
        return isApiLoaded && window.google && window.google.maps ? window.google.maps.Animation.DROP : null;
    }, [isApiLoaded]);
    const { getClientAssets, clientAssets, loading, } = companiesContext();

    useEffect(() => {
        console.log("Client Asset Frontend :" + JSON.stringify(clientAssets))

        getClientAssets(id1, id2)
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                setPosition({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });

            });
        } else {
            console.log("Geolocation is not available in your browser.");
        }
    }, []);

    useEffect(() => {
        if (Array.isArray(clientAssets) && clientAssets.length > 0 && mapRef.current) {
            const latestPoint = clientAssets[0].asset.centerCoordinates; // Get the latest point
            mapRef.current.panTo({ lat: latestPoint.lat, lng: latestPoint.lng }); // Pan to the latest point
        }
    }, [clientAssets]); // Trigger whenever points change
    return (
        <>
            <Navbar />
            <div className="assetview">
                {position.lat && position.lng ? (
                    <LoadScript
                        googleMapsApiKey="AIzaSyCMSkqh_rKJthabvItAZKNenF1ksHZbGfE"
                        mapIds={['ba39c0a5e6fb900e']}
                        onLoad={() => setIsApiLoaded(true)} // Set API loaded state to true when ready
                    >
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            ref={mapRef}
                            options={mapOptions}
                            onLoad={(map) => (mapRef.current = map)}
                        >

                            {Array.isArray(clientAssets) && clientAssets.length > 0 ? (
                                <>
                                    {clientAssets.map((assetCategory, index) => (
                                        <MarkerF
                                            key={index}
                                            position={{ lat: assetCategory.asset.centerCoordinates.lat, lng: assetCategory.asset.centerCoordinates.lng }}
                                            icon={{
                                                url: locator,
                                                scaledSize: {
                                                    width: 50,
                                                    height: 50,
                                                },
                                            }}
                                            animation={bounceAnimation}
                                        />
                                    ))}
                                </>
                            ) : null}
                            {/* POLYGON IF PRESENT */}
                            {Array.isArray(clientAssets) && clientAssets.length > 0 ? (
                                <>
                                    {clientAssets.map((assetCategory, index) => (
                                        <>
                                            {assetCategory.asset.polygon.length > 2 && (
                                                <div  key={assetCategory.asset._id}>
                                                <Polygon
                                                    paths={assetCategory.asset.polygon.map((poly) => ({ lat: poly.coords.latitude, lng: poly.coords.longitude }))}
                                                    options={polygonOptions}
                                                />
                                                </div>
                                            )}
                                        </>
                                    ))}
                                </>
                            ) : null}

                            {/* Custom Info Box positioned in the bottom-right corner */}
                            <div style={infoBoxStyle}>
                                {Array.isArray(clientAssets) && clientAssets.length > 0 ? (
                                    <div style={infoContentStyle}>
                                        <table className="performance-table" style={{ zIndex: "100" }}>
                                            <tbody className="table-body">
                                                {clientAssets.map((assetCategory, index) => (
                                                    <tr key={index} className="select">
                                                        <td className="table-desc">{assetCategory.category}</td>
                                                        <td>{assetCategory.asset.assetNumber}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <div style={infoContentStyle}>
                                        <h1 style={{ fontSize: '20px', color: "#FFF1DB", textAlign: "left", padding: "10px" }}>
                                            No Assets Found
                                        </h1>
                                    </div>
                                )}
                            </div>
                        </GoogleMap>
                    </LoadScript>
                ) : (
                    <Loader />
                )}
            </div>
        </>
    )
}


// CSS styles for the custom info box
const infoBoxStyle = {
    position: 'absolute',
    top: '80px', // Distance from the top of the map container
    left: '10px', // Distance from the left of the map container
    zIndex: 1000, // Ensure it appears above the map
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Background with transparency
    borderRadius: '8px',
    padding: '10px',
    color: '#fff',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
};

const infoContentStyle = {
    height: "350px",
    width: "350px",
    overflowY: "auto", // Handle content overflow
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
};

const polygonOptions = {
    fillColor: "#FF5733",
    fillOpacity: 0.4,
    strokeColor: "#FF5733",
    strokeOpacity: 1,
    strokeWeight: 2,
};

const containerStyle = {
    width: '100%',
    height: '100vh',
    marginRight: "auto",
    marginLeft: "auto",
    // borderBottom: "6px double #F8FAFC",
    //borderTop: "6px double #F8FAFC",
    // borderRight: "6px double #F8FAFC",
    // borderLeft: "6px double #F8FAFC",
    // cursor: plotting ? 'crosshair' : 'auto', // Set cursor style based on plottingMode
};

export default ClientAssetsScreen