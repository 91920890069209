import React, { useRef, useMemo, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import backarrow from "../assets/left.png"
import close from "../assets/close_button.png";
import assets from "../assets/assets.png"
import house from "../assets/housing.webp"
import { CTA, Brand, Navbar, Slider } from "../components";
import companiesContext from '../context/companiesContext';
import userContext from '../context/userContext';
import Loader from "../components/Loader";
import {
    Footer,
    Blog,
    Possibility,
    Features,
    WhatGPT3,
    Header,
    Services,
} from "../containers";
import "./css/clientpage.css";

// Navigation component
const SearchAsset = () => {
    return (
        <div id="search">
            <svg viewBox="0 0 420 60" xmlns="http://www.w3.org/2000/svg">
                <rect className="bar" />

                <g className="magnifier">
                    <circle className="glass" />
                    <line className="handle" x1="32" y1="32" x2="44" y2="44"></line>
                </g>

                <g className="sparks">
                    <circle className="spark" />
                    <circle className="spark" />
                    <circle className="spark" />
                </g>

                <g className="burst pattern-one">
                    <circle className="particle circle" />
                    <path className="particle triangle" />
                    <circle className="particle circle" />
                    <path className="particle plus" />
                    <rect className="particle rect" />
                    <path className="particle triangle" />
                </g>
                <g className="burst pattern-two">
                    <path className="particle plus" />
                    <circle className="particle circle" />
                    <path className="particle triangle" />
                    <rect className="particle rect" />
                    <circle className="particle circle" />
                    <path className="particle plus" />
                </g>
                <g className="burst pattern-three">
                    <circle className="particle circle" />
                    <rect className="particle rect" />
                    <path className="particle plus" />
                    <path className="particle triangle" />
                    <rect className="particle rect" />
                    <path className="particle plus" />
                </g>
            </svg>
            <input type="search" name="q" aria-label="Search for inspiration" style={{ width: "319px" }} placeholder="Search Company" />
        </div>
    );
}


const ClientScreen = () => {
    const { getCompanies,setClientCompanyID,setClientCompanyName, companies, loading, } = companiesContext();
    const {user} = userContext()

    const Card = ({ role, title, description, buttonTitle, companyId }) => {

        const history = useHistory();

        const selectCompany = (id,title) => {
            console.log("USER: "+JSON.stringify(user))
            
            setClientCompanyID(id)
            setClientCompanyName(title)
            history.push(`/client/assets/${id}/${user.sub}`);
        }

        return (
            <div className="ServiceCardd" onClick={()=>selectCompany(companyId, title)}>
                <h3><img src={assets} alt="company image"  style={{height: "200px", width: "200px",marginRight:"5px", display: "inline-block"}}/>{title.toUpperCase()}</h3>
                <div className="line"></div>
                <p>{description}</p>
                {/* <p style={{ color: "#557C56" }}>{buttonTitle}</p> */}
            </div>
        )
    };

    const Viewport = ({ companz }) => (
        <div className="viewport" style={{ marginBottom: "60px", paddingTop: "200px", paddingBottom: "100px" }}>
            <SearchAsset />

            <div className="ServiceCardds">
                {companz.map((company, index) => (
                    <Card
                        key={company.companyID}
                        cardClass="ServiceCardd10"
                        title={company.company}
                        description={company.companyEmail}
                        buttonTitle={company.role}
                        companyId={company.companyID}
                    />
                ))}
            </div>
        </div>
    );


    useEffect(() => {
        getCompanies()
        console.log("COMPANIES FROM CLIENT " + JSON.stringify(companies))
    }, []);
    return (
        <>
            <div className="gradieant__bg">
                <Navbar />
            </div>
            {companies ? <Viewport companz={companies} /> :
                <h1 style={{ color: "orange", textAlign: "center", fontSize: "50px" }}>No Companies Found</h1>}
        </>
    )
}

export default ClientScreen;