import { create } from 'zustand';
import {savePolygonService} from '../services/cartography'

// Zustand store for cartography
const cartographyContext = create((set) => ({
  points: [],
  message: null,
  loading: false,

  pushPoint: async (point) => { // Add a new point to the points array
    set({ loading: true });
    set((state) => ({
      points: [...state.points, point], // Append the new point
      loading: false, // Set loading to false if needed
    }));
  },
  clearPolygon: async () =>{
    set({ points: []});
  },
  polygonSave: async (companyID, categoryId, assetNumber, points) => {
    set({ loading: true });
    if(Array.isArray(points) && points.length > 0){
      const response = await savePolygonService(companyID, categoryId, assetNumber,points);
      set({ loading: false });
      console.log("RESPONSE :"+JSON.stringify(response));
      // if(response.status === 201){
      //   set({message: response.message})
      // }
    }else{
      set({ loading: false });
      console.log("No Point's to Save")
      return
    }
   
    // if (response !== undefined && Array.isArray(response.assets)) {
    //   set({ assets: response.assets, loading: false });
    //   set({ selectedCompanyID: companyID });
    // } else {
    //   set({ assets: null, loading: false });
    // }
  },
}));

export default cartographyContext;