import React, { useRef, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import MenuOptions from "./DashboardMenus"
import companiesContext from '../context/companiesContext';
import adminContext from '../context/dashAdminContext'





//COMPONENTS
const Card = ({ title, description, actionFunction }) => {

    return (
        <div className="ServiceCardd" style={{ padding: "20px" }} onClick={actionFunction}>
            {/* <div className={`ServiceCardd_img ${cardClass}`}>
        <div className="plus"><p>+</p></div>
      </div> */}
            <h3>{title}</h3>
            <div className="line"></div>
            <p style={{ marginTop: "15px" }}>{description}</p>
            {/* <button onClick={cardLink} className="cardsbuttonz" style={{ width: "45%", height: "10%", marginRight: "auto", marginLeft: "auto", marginBottom: "60px", background: "linear-gradient( 110.3deg,  rgba(73,93,109,1) 4.3%, rgba(49,55,82,1) 96.7% )" }}>{buttonTitle}</button> */}
        </div>
    )
};

const DashboardAdmin = () => {
    const [assignRoles, setAssignRoles] = useState(false);
    const [search, setSearch] = useState(true);
    const [tiles, setTiles] = useState(true);
    const [searchedUser, setSearchedUser] = useState(null)

    const showAssignRole = () => {
        setTiles(false);
        setAssignRoles(true);
    }

    const showTiles = () =>{
        if(search){
          setSearch(false) 
        }else{
            setSearch(true) 
        }
        setTiles(true);
    }

    //COMPONENTS
    const Role = () => {
        const [phoneNumber, setPhoneNumber] = useState('');
        const [selectedRole, setSelectedRole] = useState("");
        const { selectedCompanyID } = companiesContext();
        const { assignRole, message, searchUser, foundUser, removeSearch } = adminContext();


        const roles = [
            "Cartography",
            "Survey",
            "Sales",
            "Reception",
            "Manager",
            "Director",
            "Admin"
        ]

        const findUser = () => {
            searchUser("+" + phoneNumber)
        }

         // Event handler for updating the selected role
    const handleSelectRole = (event) => {
        setSelectedRole(event.target.value); // Get the value from the select element
        console.log("ROLE SELECT :" + selectedRole)
    }; // End of handleSelectCategoryChange

        const submitRole =()=>{
            if(selectedRole !== undefined) {
            assignRole(searchedUser.phone,selectedCompanyID,selectedRole)
            }
        }
        
        const cancelAssignment = () => {
            removeSearch()
            setSearchedUser(null)
            setSearch(true)
        }
        useEffect(() => {
            if (foundUser) {
                setSearchedUser(foundUser)
                setSearch(false)
                console.log("Results :" + foundUser)
            }

        }, [foundUser]);
        return (
            <>
                {search && (
                    <div style={{ background: "linear-gradient(120deg, #f6d365 0%, #fda085 100%)", padding: "20px", display: "flex" }}>
                        <div>
                            <label for="user-role" style={{ marginLeft: "20px", marginBottom: "10px" }}>Enter User's Phone</label> <br />
                            <PhoneInput country={"zw"} value={phoneNumber} onChange={setPhoneNumber} />
                        </div>
                        <button style={{ width: "200px", height: "40px", marginTop: "30px", marginLeft: "5px" }} onClick={findUser}>Search User</button>
                    </div>
                )}

                {!search && searchedUser && (
                    <form style={{ marginBottom: "20px" }}>
                        <input type="text" className="dashSearchInput" placeholder="Name" value={searchedUser.name} readonly />
                        <input type="text" className="dashSearchInput" placeholder="phone" value={searchedUser.phone} readonly />
                        <input type="text" className="dashSearchInput" placeholder="National ID" value={searchedUser.NationalId} readonly />
                        <input type="text" className="dashSearchInput" placeholder="Email" value={searchedUser.email} readonly />
                        <label for="user-role" style={{ marginLeft: "10px", color: "black" }}>Select Role</label> <br />
                        <select name="Role" className="dashSearchInput" value={selectedRole} onChange={handleSelectRole}>
                        <option value="">Select Role</option>
                            {roles.map((role) => (
                                <option value={role}>{role}</option>
                            ))}
                        </select>
                        <button style={{ width: "60%", marginLeft: "10px", marginBottom: "10px", backgroundColor: "#3C552D" }} onClick={submitRole}>Apply Role</button>
                        <button style={{ width: "60%", marginLeft: "10px", backgroundColor: "#8D0B41" }} onClick={cancelAssignment}>Cancel</button>
                    </form>
                )}
            </>
        )
    }

    //Functions for features

    const settingOptions = [
        {
            title: "Users",
            action: showTiles
        },
        {
            title: "Logs",
            action: ""
        }
    ]

    const userOptions = [
        {
            id: 1,
            title: "Assign Role",
            description: "Assign Tasks to Employees",
            buttonTitle: "Roles",
            action: showAssignRole
        },
        {
            id: 2,
            title: "Users",
            description: "All Users",
            buttonTitle: "Users",
            action: showAssignRole
        },
    ]

    return (
        <div className='stats-container' id='stats-element'>
            <div className='map-container'>
                <MenuOptions menuTitle="ADMIN OPTIONS" options={settingOptions} />
                {tiles && (
                    <div className="options">
                        {userOptions.map((setting) => (
                            <Card
                                key={setting.id}
                                cardClass="ServiceCardd10"
                                title={setting.title}
                                description={setting.description}
                                buttonTitle={setting.buttonTitle}
                                actionFunction={setting.action}
                            />
                        ))}
                    </div>
                )}

                {assignRoles && (
                    <Role />
                )}
            </div>
        </div>
    );
}

export default DashboardAdmin