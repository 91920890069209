import React, { useRef, useMemo, useEffect, useState } from "react";
import proj4 from "proj4";
import cartographyContext from "../context/cartographyContext"
import companiesContext from '../context/companiesContext';

const systems = [
    "WGS84 (GPS)", "Gauss-Krüger"
]
const MapPlot = ({ assets }) => {
    const [selectedSystem, setSelectedSystem] = useState(""); // Selected coordinate system
    const [savePolygon, setSavePolygon] = useState(false)
    const [category, setCategory] = useState(null);
    const latitudeRef = useRef();
    const longitudeRef = useRef();
    const assetNumberRef = useRef()


    // Access Zustand store functions
    const { pushPoint, clearPolygon,polygonSave, points, message } = cartographyContext((state) => ({
        pushPoint: state.pushPoint,
        clearPolygon: state.clearPolygon,
        polygonSave: state.polygonSave,
        points: state.points,
        message: state.message
    }));
    const { selectedCompanyID } = companiesContext();

    // Handle system selection change
    const handleSelectedSystem = (event) => {
        setSelectedSystem(event.target.value);
    };

    const handleCategory = (e) => {
        setCategory(e.target.value)
        console.log("Category ID" + e.target.value)
    }

    const handleSavePolygon = () => {
        setSavePolygon(true)
    }

    const saveToDatabase = () =>{
        //send to ApI companyID, categoryID, Number
        polygonSave(selectedCompanyID, category, assetNumberRef.current.value, points)
        setSavePolygon(false)
    }
    const convertGaussToGPS = (lat, long) => {
        try {
            // Gauss-Krüger (Arc 1950, Lo 31)
            //    const gaussKruger = `
            //    +proj=tmerc                  // Transverse Mercator projection
            //    +lat_0=0                     // Latitude of origin
            //    +lon_0=31                    // Central meridian (Lo 31°)
            //    +k=1                         // Scale factor
            //    +x_0=0                       // False easting (X-Const)
            //    +y_0=0                       // False northing (Y-Const)
            //    +ellps=clrk80                // Clarke 1880 ellipsoid
            //    +datum=arc1950               // Arc 1950 datum
            //    +units=m                     // Units in meters
            //    +no_defs`;                   // No additional definitions
            const gaussKruger = "+proj=tmerc +lat_0=0 +lon_0=31 +k=1 +x_0=0 +y_0=500000 +ellps=clrk80 +datum=arc1950 +units=m +no_defs";
            // WGS84 (GPS) projection
            const wgs84 = proj4.defs("EPSG:4326");

            // Perform the conversion
            const [convertedLong, convertedLat] = proj4(gaussKruger, wgs84, [long, lat]);

            // Return the converted coordinates
            return { lat: convertedLat, long: convertedLong };
        } catch (error) {
            console.error("Conversion failed:", error);
            throw new Error("Failed to convert Gauss-Krüger to GPS coordinates.");
        }
    };

    const handleAddCoordinates = (e) => {
        e.preventDefault();

        if (selectedSystem === null) {
            alert("Please select a co-ordinate system")
            return
        }
        const latitude = parseFloat(latitudeRef.current.value);
        const longitude = parseFloat(longitudeRef.current.value);

        if (isNaN(latitude) || isNaN(longitude)) {
            alert("Please enter valid latitude and longitude values.");
            return;
        }

        try {
            let point = { lat: latitude, long: longitude };

            if (selectedSystem === "Gauss-Krüger") {
                point = convertGaussToGPS(latitude, longitude); // Attempt conversion
                console.log("Converted Gauss to GPS:", point);
            }

            // Push the point to Zustand store
            pushPoint(point);
            console.log("Added point:", point);

            // Clear input fields
            latitudeRef.current.value = "";
            longitudeRef.current.value = "";
        } catch (error) {
            alert(error.message); // Show an error message to the user
        }
    };

    const handleClearCoordinates = () => {
        clearPolygon()
        setSavePolygon(false)
    }

    const cancelSave = () => {
        clearPolygon()
        setSavePolygon(false)
    }

    useEffect(() => {
        if (message === "Polygon data saved successfully") {
            alert("Polygon Saved")
            clearPolygon()
            setSavePolygon(false)
        }

    }, [message]);
    return (
        <>
            {!savePolygon && (
                <div className="log-coordinates">
                    <form style={{ marginBottom: "20px", width: "450px", paddingLeft: "0", paddingRight: "10px" }}>
                        <label for="user-role" style={{ marginLeft: "10px", color: "white" }}>PLOT POLYGON</label> <br />
                        <select name="Coordinate System" className="dashSearchInput" value={selectedSystem} onChange={handleSelectedSystem}>
                            <option value="">Select Coordinate System</option>
                            {systems.map((system) => (
                                <option value={system}>{system}</option>
                            ))}
                        </select>
                        <input type="text" className="dashSearchInput" placeholder="Latitude (X)" ref={latitudeRef} />
                        <input type="text" className="dashSearchInput" placeholder="Longitude (Y)" ref={longitudeRef} />
                        <button style={{ width: "60%", color: "black", marginLeft: "10px", marginBottom: "10px", backgroundImage: "radial-gradient( circle 1224px at 10.6% 8.8%,  rgba(255,255,255,1) 0%, rgba(153,202,251,1) 100.2% )" }} onClick={handleAddCoordinates} >Next</button>
                        <button style={{ width: "60%", color: "black", marginLeft: "10px", marginBottom: "10px", backgroundImage: "radial-gradient( circle 1224px at 10.6% 8.8%,  rgba(255,255,255,1) 0%, rgba(153,202,251,1) 100.2% )" }} onClick={handleSavePolygon}>Save</button>
                        <button style={{ width: "60%", marginLeft: "10px", backgroundColor: "#F96E2A" }} onClick={handleClearCoordinates}>Clear</button>
                    </form>
                </div>
            )}

            {savePolygon && (
                <div className="log-coordinates">
                    <form style={{ marginBottom: "20px", width: "450px", paddingLeft: "0", paddingRight: "10px" }}>
                        <label for="user-role" style={{ marginLeft: "10px", color: "green" }}>SAVE POLYGON</label> <br />
                        {assets && (
                            <select name="Coordinate System" className="dashSearchInput" value={category} onChange={handleCategory}>
                                <option value="">Select Category</option>
                                {assets.map((asset) => (
                                    <option key={asset._id} value={asset._id}>{asset.category}</option>
                                ))}
                            </select>
                        )}
                        <input type="text" className="dashSearchInput" placeholder="Number" ref={assetNumberRef} />
                        <button style={{ width: "60%", marginLeft: "10px", marginBottom: "10px", backgroundImage: "radial-gradient( circle farthest-corner at 10% 20%,  rgba(52,235,107,1) 23.1%, rgba(0,136,73,1) 80.2% )" }} onClick={saveToDatabase}>Save</button>
                        <button style={{ width: "60%", marginLeft: "10px", backgroundColor: "#F96E2A" }} onClick={cancelSave}>Cancel</button>
                    </form>
                </div>
            )}
        </>
    )

}

export default MapPlot